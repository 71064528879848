import { createI18n } from 'vue-i18n';
import deTranslations from './lang/de';
import dkTranslations from './lang/dk';
import fiTranslations from './lang/fi';
import noTranslations from './lang/no';
import ukTranslation from './lang/uk';
import usTranslations from './lang/us';
import seTranslations from './lang/se';

const messages = {
  'sv-SE': Object.assign(seTranslations),
  en: Object.assign(usTranslations),
  de: Object.assign(deTranslations),
  dk: Object.assign(dkTranslations),
  fi: Object.assign(fiTranslations),
  no: Object.assign(noTranslations),
  uk: Object.assign(ukTranslation),
  us: Object.assign(usTranslations),
  se: Object.assign(seTranslations),
};

const locale = navigator.language;

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: locale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
