import { RouteRecordRaw } from 'vue-router';

export const remoteConfirmRoutes: RouteRecordRaw[] = [
  {
    path: '/:country?/fv/:code',
    name: 'app-base',
    component: () => import('@/features/remote-confirm/views/AppBase.vue'),
    meta: {
      requireAuth: false,
      showCountrySelect: false,
    },
    children: [
      {
        path: '/:country?/fv/:code/link-expired',
        name: 'confirm-code-expired',
        component: () =>
          import('@/features/remote-confirm/views/CodeExpired.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '/:country?/fv/:code',
        name: 'confirm-data-fetch',
        component: () =>
          import('@/features/remote-confirm/views/FetchData.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '/:country?/fv/:code/booking-expired',
        name: 'confirm-booking-expired',
        component: () =>
          import('@/features/remote-confirm/views/BookingExpired.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '/:country?/fv/:code/select-time',
        name: 'remote-confirm-select-time',
        component: () =>
          import('@/features/remote-confirm/views/SelectNewTime.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '/:country?/fv/:code/confirm-booking',
        name: 'confirm-booking',
        component: () =>
          import('@/features/remote-confirm/views/ConfirmBooking.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '/:country?/fv/:code/validate-account',
        name: 'validate-account',
        component: () =>
            import('@/features/remote-confirm/views/ValidateAccount.vue'),
        meta: {
          redirectToAuth: true,
          requireAuth: true,
        },
      },
      {
        path: '/:country?/fv/:code/success',
        name: 'confirm-success',
        component: () =>
          import('@/features/remote-confirm/views/ConfirmedSuccess.vue'),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];
